@import url('https://fonts.googleapis.com/css2?family=Oleo+Script+Swash+Caps:wght@700&family=Rajdhani:wght@300&display=swap');

* {
  box-sizing: border-box;
  margin: auto;
  padding: auto;
  }

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F56600;
    padding: 16px; /* Adjust padding as needed */
  }
  
  /* Logo image */
.navbar-logo {
max-width: 100px; /* Adjust the maximum width of the logo */
}
  
.navbar ul {
  list-style-type: none;
  margin: 0;
  padding:4px;
  overflow: hidden;
  background-color: #F56600;
}

.navbar li {
  float: left;
  margin-right: 20px;
  background-color: #F56600;
}

.navbar li a {
  display: flex;
  color: #ffffff;
  background-color: #F56600;
  text-align: center;
  padding:32px;
  text-decoration: none;
  
  font-family: 'Oleo Script Swash Caps', cursive;
}

.navbar li a:hover {
  opacity: 0.7; 
}
  
.navbar li:last-child {
margin-right: 0; /* Remove margin for the last list item */
 background-color: #F56600;
}
  
/* Logo image */
.navbar-logo {
  max-width: 30px; /* Set a maximum width for the image */
  max-height: 20px; /* Set a maximum height for the image */
  background-color: #F56600;
  /* If you want the image to scale proportionally, you can use only one of max-width or max-height. */
}

/* Search bar */
.navbar-search {
padding: 8px;
border: none;
border-radius: 4px;
font-size: 16px;
background-color: #ffffff;
}

.navbar img {
  max-width: 40px; /* Adjust the maximum width for the logo images */
  max-height: 40px; /* Adjust the maximum height for the logo images */
  /* If you want the images to scale proportionally, you can use only one of max-width or max-height. */
}

/* Optional: Add hover effects for logos */
.navbar a:hover img {
  opacity: 0.7; 
}

.navbar a {
  display: flex; /* Use flex to create a horizontal layout for each anchor element */
  flex-direction: column;      
  align-items: center; /* Vertically center the image and text within each anchor */
  margin: 0 10px; /* Adjust spacing between logos as needed */
  text-decoration: none;
  color: #fff; /* Add color to the text */
  font-weight: bold; 
}

.home{
  background-color: rgb(0, 0, 0);
}